import React, { useState, Component } from "react";
import Styled from "./index.styles";
import Image from "../../components/image";
import { Theme } from "../../types/common";

export default function main({
  latestPostList,
}: {
  latestPostList: Array<any>;
}) {
  return (
    <>
      <Styled.container>
        <div className="image-container">
          <Image imgName="qus_face_line.png" className="face" />

          <div className="cloud-wrapper yellow">
            <Image imgName="cloud_yellow.png" />

            <div className="theme-list">
              <a className="theme" href="/developer" target="_blank">
                <Image imgName="icon_developer.png" />
              </a>

              <a className="theme" href="/life" target="_blank">
                <Image imgName="icon_life.png" />
              </a>

              <a className="theme" href="/civichacker" target="_blank">
                <Image imgName="icon_civichacking.png" />
              </a>
            </div>

            {/* <i className="arrow las la-hand-point-up"></i> */}
          </div>

          <div className="cloud-wrapper blue">
            <Image imgName="cloud_blue.png" />

            <div className="post-list">
              {latestPostList.map(({ node }) => {
                return (
                  <a
                    className="post"
                    key={node.id}
                    href={`/${node.fields.theme}/post${node.fields.slug}`}
                    target="_blank"
                  >
                    <div className="header">
                      <div className="date">{node.fields.prefix}</div>
                      <div className="theme">
                        {themeFormatter(node.fields.theme)}
                      </div>
                    </div>

                    <div className="title">{node.frontmatter.title}</div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </Styled.container>
    </>
  );
}

function themeFormatter(theme: Theme) {
  switch (theme) {
    case "civicHacker": {
      return "시빅해킹";
    }
    case "life": {
      return "Life";
    }
    case "developer": {
      return "프로그래밍";
    }
    default: {
      return "";
    }
  }
}
