import styled, { css } from "styled-components";
import { mobile } from "../../styles/functions";

const container = styled.div`
  background-color: #707582;
  min-height: 100vh;
  height: 100%;
  @media (max-height: 500px) {
    /* 모바일 가로모드에서 height가 다 보이도록함 */
    min-height: 1000px;
  }
  @media (min-height: 501px) and (max-height: 904px) {
    min-height: 100vh;
  }
  min-width: 100vw;
  width: 100%;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  > .image-container {
    position: relative;
    margin-left: -12%;

    ${mobile(css`
      width: 100%;
      margin-left: 0;
    `)}

    > .face {
      width: 500px;

      ${mobile(css`
        width: 80%;
        margin-left: 5%;
      `)}
    }

    > .cloud-wrapper {
      position: absolute;
      width: 100%;
    }

    > .yellow {
      left: -140px;
      top: -142px;
      transform: rotate(-11deg);

      ${mobile(css`
        width: 86%;
        left: -5%;
        top: -32%;
      `)}

      > .theme-list {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 29%;
        width: 53%;
        transform: rotate(10deg);

        > .theme {
          display: block;
          width: 100%;
        }
      }
      /*
      > .arrow {
        position: absolute;
        left: 46%;
        bottom: 20%;
        color: #fff;
        font-size: 20px;
        transform: rotate(10deg);

        @keyframes fadeAndTravel {
          0% {
            opacity: 0.7;
            bottom: 20%;
          }

          50% {
            opacity: 1;
            bottom: 18%;
          }

          100% {
            opacity: 0.7;
            bottom: 20%;
          }
        }

        animation-duration: 1.8s;
        animation-name: fadeAndTravel;
        animation-iteration-count: infinite;
      } */
    }

    > .blue {
      right: -338px;
      bottom: -71px;

      ${mobile(css`
        right: -3%;
        bottom: -39%;
        width: 88%;
      `)}

      > .post-list {
        position: absolute;
        width: 63%;
        height: 100%;
        top: 0;
        left: 20%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        > .post {
          display: block;

          &:not(:last-child) {
            margin-bottom: 6%;
          }

          > .header {
            display: flex;
            align-items: center;
            margin-bottom: 2px;
            color: #343a40;

            > .date {
            }

            > .theme {
              margin-left: 10px;
              font-weight: bold;
            }
          }

          > .title {
            color: #f8f9fa;
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }
`;

export default {
  container,
};
