import { css, FlattenInterpolation } from "styled-components";

/** 모바일 size용 스타일 */
export function mobile(styles: FlattenInterpolation<any>) {
  return css`
    /*  Small devices (landscape phones, less than 768px) */
    @media (max-width: 767.98px) {
      ${styles}
    }
  `;
}

/** 태블릿 size용 스타일 */
export function tablet(styles: FlattenInterpolation<any>) {
  return css`
    /* Medium devices (tablets, less than 992px) */
    @media (max-width: 991.98px) {
      ${styles}
    }
  `;
}
