import React from "react";
import { graphql } from "gatsby";
// line-awesome
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";

import SEO from "../components/seo";

import Main from "../pageContainers/main";

import { themeContents } from "../pageContainers/mainLegacy";

// import "../styles/pages/main/index.scss";
import { ISEOProps } from "../types/common";

const seoData: ISEOProps = {
  title: "쿠스의 이야기",
  description: `쿠스의 이야기들 Story of Qus, ${themeContents
    .map(item => item.title)
    .join(", ")}`,
};

const Container = ({ data }) => {
  const {
    allMarkdownRemark: { edges },
  } = data;

  return (
    <>
      <SEO {...seoData} />
      <Main latestPostList={edges} />
    </>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 3
      sort: { fields: fields___prefix, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            thumbNail {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            series
            theme
            prefix(formatString: "YYYY.MM.DD")
          }
          excerpt
        }
      }
    }
  }
`;

export default Container;
