import React, {
  useState,
  Component,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ThemeContent } from ".";

interface IProps {
  isActivePrevBtn: boolean;
  setIsActivePrevBtn: Dispatch<SetStateAction<boolean>>;
  isActiveNextBtn: boolean;
  setisActiveNextBtn: Dispatch<SetStateAction<boolean>>;
  setSliderIndex: Dispatch<SetStateAction<number>>;
  themeContents: ThemeContent[];
}

const Container = (props: IProps) => {
  const sliderSetting = {
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    fade: true,
    arrows: false,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
  };

  const [slider, setSlider] = useState({} as any);

  return (
    <div id="main-slider">
      <Slider ref={slider => setSlider(slider)} {...sliderSetting}>
        {props.themeContents.map(t => {
          return (
            <h1 className="slider-title" key={t.key}>
              {t.title}
            </h1>
          );
        })}
      </Slider>
      <div className="btn-container">
        <i
          id="arrow-right"
          className={`arrow las la-caret-right ${
            props.isActivePrevBtn ? "active" : ""
          }`}
          onClick={() => handleClickNext(slider)}
        />
        <i
          id="arrow-left"
          className={`arrow las la-caret-left ${
            props.isActiveNextBtn ? "active" : ""
          }`}
          onClick={() => handleClickPrev(slider)}
        />
      </div>
    </div>
  );

  function handleBeforeChange(oldIndex: number, newIndex: number) {
    if (newIndex > oldIndex) {
      props.setisActiveNextBtn(true);
    } else {
      props.setIsActivePrevBtn(true);
    }
  }

  function handleAfterChange(index) {
    props.setisActiveNextBtn(false);
    props.setIsActivePrevBtn(false);
    props.setSliderIndex(index);
  }
};

function handleClickPrev(slider) {
  slider.slickPause();
  slider.slickPrev();
}

function handleClickNext(slider) {
  slider.slickPause();
  slider.slickNext();
}

export default Container;
