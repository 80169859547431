import React, { useState, Component } from "react";
import Slider from "./Slider";
import { Link } from "gatsby";
import Image from "../../components/image";

export interface ThemeContent {
  key: string;
  title: string;
  message: any;
  link: string;
}
export const themeContents: ThemeContent[] = [
  {
    key: "Life",
    title: "일상 Life",
    message: (
      <>
        지내면서 생기는
        <br />
        잊고싶지 않은 이야기들을
        <br />
        기록합니다
      </>
    ),
    link: "/life",
  },
  {
    key: "CivicHacker",
    title: "시빅해커 CivicHacker",
    message: (
      <>
        기술을 이용해
        <br />
        세계를 바꾸려는
        <br />
        시도들을 하고 있습니다.
      </>
    ),
    link: "/civichacker",
  },
  {
    key: "Developer",
    title: "개발자 Developer",
    message: (
      <>
        개발하면서
        <br />
        기억하고 싶은 것들,
        <br />
        함께 나누고 싶은 이야기들을 기록합니다.
      </>
    ),
    link: "/developer",
  },
];

interface IProps {}
const Container = (props: IProps) => {
  const [isActivePrevBtn, setIsActivePrevBtn] = useState(false);
  const [isActiveNextBtn, setisActiveNextBtn] = useState(false);
  const [sliderIndex, setsliderIndex] = useState(0);

  return (
    <div className="container" id="main-container">
      <Image imgName="main-star-white.png" className="star star-1" />
      <Image imgName="main-star-blue.png" className="star star-2" />

      <div className="main-image-container">
        <Image imgName="main-space-ship.png" className="space-ship" />
        <Image
          imgName="main-space-ship-light.png"
          className={`space-ship-light ${
            isActivePrevBtn || isActiveNextBtn ? "dimmed" : ""
          }`}
        />
        <Slider
          isActivePrevBtn={isActivePrevBtn}
          setIsActivePrevBtn={setIsActivePrevBtn}
          isActiveNextBtn={isActiveNextBtn}
          setisActiveNextBtn={setisActiveNextBtn}
          setSliderIndex={setsliderIndex}
          themeContents={themeContents}
        />
        <div
          className={`content-by-theme ${
            isActivePrevBtn || isActiveNextBtn ? "dimmed" : ""
          }`}
        >
          <div className="message">{themeContents[sliderIndex].message}</div>
          <a href={themeContents[sliderIndex].link}>자세히보기</a>
        </div>
      </div>
    </div>
  );
};

export default Container;
